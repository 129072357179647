import * as React from "react";
import Layout from "../components/Layout";
import { css } from "@emotion/react";
import Container from "../components/Container";
import Title from "../components/TitleComponent";

const LocationPage = () => {
  return (
    <div css={three}>
      <Layout title="Sumadhura Folium | Sumadhura Folium Location">
        <Container>
        <Title title="Location Advantages" size="3vw" color="#7a8c57" />
          <p>
            Book a pair of & three BHK residences with world category amenities
            at Sumadhura Folium in Whitefield. on the brink of Offices
            faculties & amusement | speedily Progressing Construction | Visit
            these days. Virtual Tour out there. transfer Floor Plans. speedy
            Construction. Best-in-Class Amenities..
          </p>
        </Container>
      </Layout>
    </div>
  );
};

export default LocationPage;

const three = css`
  padding-top:10vh;
`;
